import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Labor } from '../interface/labor';
import { first, switchMap } from 'rxjs/operators';
import { LaborUtilities } from '../class/labor-utilities';
import { Observable, firstValueFrom } from 'rxjs';
import { ApiPaginatedQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class LaborService {
  private _isFromMatrix = true;
  labors: Labor[] = [];

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private _dataService: DataService
  ) { }

  async get(id: string, cnpj?: string): Promise<Labor> {
    const url = `${environment.mkgoURL}/api/v1/labors/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj || this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    if (!response.labors.length) {
      throw new Error(`Labor with id: ${id} has not found.`);
    }
    return response.labors[0];
  }

  async getAll(): Promise<Labor[]> {
    const url = `${environment.mkgoURL}/api/v1/labors`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    this.labors = (response["labors"] as Labor[]).reverse()
    return this.labors
  }

  getPaginated(apiParams: ApiPaginatedQueryParams, cnpj?: string): Observable<ApiPaginatedResponse<Labor>> {
    const url = `${environment.mkgoURL}/api/v1/labors/pages`;
    const params = PaginationService.getParams(apiParams);
    return this._dataService.httpOptions(cnpj || this._isFromMatrix, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedResponse<Labor>>(url, options)),
      first()
    );
  }

  async addOrUpdate(labor: Labor): Promise<Labor> {
    const url = `${environment.mkgoURL}/api/v1/labors`;
    const apiLabor = LaborUtilities.complyAPI(labor)
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.post(url, apiLabor, header).pipe(first()).toPromise();
    return resp
  }

}
