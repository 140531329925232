

/**
 * Workarround API fails at saving some monetary values on database
 * 
 * before to save into dataBase, the API multiplies the received value by 100 and
 * convert it into a integer. like this:
 * @ex 
 *  - Infomed value: `10.00`
 *  - value calculated by API: `10 * 100` = `1000`
 *  - Integer stored into database: `1000`
 *  - Value returned to frontend: `1000 / 100` = `100`
 * 
 * But whith some values, this flow fails, 
 * because the `parseInt` ignore all decimal points of multiplied value
 * @ex
 * - Infomed value: `5.10`
 * - value calculated by API: `5.10 * 100` = `509.99999999999994`
 * - Integer stored into database: `509`
 * - Value returned to frontend: `509 / 100`) = `5.09`❌
 * 
 * @solution Send a additional small value (0.0000000001) when preview that this error will occur
 * - Infomed value: `5.10`
 * - Treated value: `ApiFloat(5.10)` = `5.1000000001`
 * - value calculated by API: `5.1000000001 * 100` = `510.00000000999995`
 * - Integer stored into database: `510`
 * - Value returned to frontend: `510 / 100` = `5.10`✅
 * 
 * @param rigthValue The value that we want to prevent imprecision
 * @param decimalPoints The decimal points to consider, default is 2
 * @returns The value that should be sent to API to avoid lose information
 */
export function ApiFloat(rigthValue: number, decimalPoints = 2): number {
    const diferenceLimit = 0.9 * Math.pow(10, -decimalPoints);
    const correctionValue = 1e-10;
    const apiValue = parseInt(`${rigthValue * 100}`) / 100;
    const difference = Math.abs(rigthValue - apiValue);
    if (difference > diferenceLimit) {
      return rigthValue + correctionValue;
    }
    return rigthValue;
  }

